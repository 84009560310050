@import "styles/variables";

.blockWrapper {
  margin: 50px 0;
  @media screen and (max-width: $breakpoint-lg) {
    margin: 16px 0 50px;
  }
}

.row {
  display: grid;
  grid-template-rows: repeat(4, min-content);
  $columnGap: 20px;
  column-gap: $columnGap;
  &.vertical {
    grid-template-columns: 1fr 0.5fr;
  }
  &.horizontal {
    grid-template-columns: repeat(2, calc(50% - $columnGap / 2));
  }
  @media screen and (max-width: $breakpoint-md) {
    &.vertical,
    &.horizontal {
      grid-template-columns: 1fr;
    }
  }
}

.heading,
.subheading,
.text {
  grid-column: 1;
}

.subheading {
  @media screen and (max-width: $breakpoint-sm) {
    font-size: 20px;
    line-height: 22px;
  }
}

.text {
  &:not(:first-child):not(.heading + .text) {
    margin-top: 12px;
  }
}
