@import "styles/variables";
@import "styles/mixins";

.wrapper {
  min-height: 427px;
  display: flex;
  align-items: center;
  padding-top: 80px;
  padding-bottom: 80px;
  position: relative;

  @media screen and (max-width: $breakpoint-lg) {
    min-height: 360px;
  }

  @media screen and (max-width: $breakpoint-sm) {
    min-height: 256px;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.text {
  margin-top: 24px;
  margin-bottom: 32px;
}

.cta {
  padding: 10px 38px;
  background-color: $teal;
  color: $white;

  &.longCaption {
    display: inline-flex;
    line-height: 22.5px;
  }

  @include transition-default(opacity);
  &:hover {
    color: $white;
    opacity: 0.8;
  }
}
