@import "styles/variables";

.scoreContainer {
  grid-column: 1;
  display: flex;
  align-items: flex-start;
  @media screen and (max-width: $breakpoint-md) {
    margin-bottom: 20px;
  }
}

.scoreBorder {
  width: 170px;
  height: 170px;
  border: 5px solid $teal;

  @media screen and (max-width: $breakpoint-lg) {
    width: 120px;
    height: 120px;
  }

  @media screen and (max-width: $breakpoint-sm) {
    width: 90px;
    height: 90px;
  }
}

.scoreContent {
  position: relative;
  left: -90px;
  top: -3px;
  align-self: center;
  display: flex;
  align-items: center;

  @media screen and (max-width: $breakpoint-lg) {
    left: -60px;
  }

  @media screen and (max-width: $breakpoint-sm) {
    left: -45px;
  }
}

.score {
  font-size: 90px;
  line-height: 1;
  font-weight: 700;
  color: $black;

  @media screen and (max-width: $breakpoint-lg) {
    font-size: 60px;
  }

  @media screen and (max-width: $breakpoint-sm) {
    font-size: 45px;
  }
}

.scoreText {
  margin-left: 12px;
  font-size: 22px;
  white-space: pre-line;

  @media screen and (max-width: $breakpoint-sm) {
    font-size: 16px;
  }
}
