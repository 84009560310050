@import "styles/variables";
@import "styles/mixins";

.container {
  position: relative;
  width: 100%;
  padding-top: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  * {
    pointer-events: none;
  }
  &.hover {
    .inner {
      opacity: 1;
      background-color: $white;
    }
    .borderTop,
    .borderRight,
    .borderBottom,
    .borderLeft {
      @include transition-default(width height, 0.2s);
    }
    .borderTop {
      width: 100%;
    }
    .borderRight {
      height: 100%;
      transition-delay: 0.2s;
    }
    .borderBottom {
      width: 100%;
      transition-delay: 0.4s;
    }
    .borderLeft {
      height: 100%;
      transition-delay: 0.6s;
    }
    .title {
      transform: translateX(0);
    }
    .text {
      @include transition-default(transform opacity, 0.5s, ease);
      transition-delay: 0.3s;
      transform: translateY(0);
      opacity: 1;
    }
  }
}

.inner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  opacity: 0;

  @include transition-default(background-color opacity, 0.5s);

  @media screen and (max-width: $breakpoint-md) {
    padding: 40px;
  }

  @media screen and (max-width: $breakpoint-sm) {
    padding: 30px;
  }
}

.innerInfo {
  width: 100%;
}

.borderTop,
.borderRight,
.borderBottom,
.borderLeft {
  position: absolute;
  background-color: $teal;
}

.borderTop,
.borderBottom {
  width: 0;
  height: 2px;
}

.borderRight,
.borderLeft {
  width: 2px;
  height: 0;
}

.borderTop {
  top: 0;
  left: 0;
}

.borderRight {
  top: 0;
  right: 0;
}

.borderBottom {
  bottom: 0;
  right: 0;
}

.borderLeft {
  bottom: 0;
  left: 0;
}

.title {
  transform: translateX(-40px);

  @include transition-default(transform, 0.5s);

  @media screen and (max-width: $breakpoint-md) {
    transform: translateX(-30px);
  }

  @media screen and (max-width: $breakpoint-sm) {
    transform: translateX(-20px);
  }
}

.text {
  margin-top: 40px;
  transform: translateY(-30px);
  opacity: 0;

  @media screen and (max-width: $breakpoint-md) {
    margin-top: 30px;
    transform: translateY(-20px);
  }

  @media screen and (max-width: $breakpoint-sm) {
    margin-top: 20px;
    transform: translateY(-10px);
  }
}

.cta {
  color: $teal;
  pointer-events: all;
  &:hover {
    color: $teal;
  }
}
