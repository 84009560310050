@import "styles/variables";

.HighlightRoot {
  @media screen and (min-width: 1025px) {
    border-right: 1px solid $gray-550;
  }
}

.HighlightList {
  list-style: none;
  padding: 0 5px 20px 0;
  margin: 0;
}

.HighlightItem {
  position: relative;
  margin-bottom: 10px;
  padding-left: 16px;

  @media screen and (max-width: $breakpoint-lg) {
    margin-bottom: 4px;
  }

  @media print {
    margin-bottom: 0;
    font-size: 13px;
  }

  &::before {
    content: "";
    border-radius: 50%;
    position: absolute;
    top: 9px;
    left: 2px;
    width: 6px;
    height: 6px;
    background-color: $black;
    color-adjust: exact !important;

    @media screen and (max-width: $breakpoint-lg) {
      top: 11px;
    }
  }
}

.highlightText {
  @media screen and (max-width: $breakpoint-sm) {
    font-size: 14px;
  }
}
