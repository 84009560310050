@import "styles/variables";
@import "styles/mixins";

.content {
  z-index: 4;
  padding-bottom: 20px;
}

.image {
  position: absolute;
  width: 65%;
  height: 100%;
  max-height: 600px;
  display: block;
  z-index: 0;
  top: 0;
  right: 0;

  @media screen and (max-width: $breakpoint-md) {
    width: 100%;
  }
}

.title {
  font-size: 18px;

  @media screen and (max-width: $breakpoint-lg) {
    font-size: 16px;
  }
}

.panel {
  background-color: $white;
  margin-bottom: 15px;
  &:global(.ant-collapse-item .ant-collapse-header) {
    padding-left: 15px;
    & :global(.ant-collapse-arrow) {
      color: $black;
    }
  }
  &:global(.ant-collapse-item-active),
  &:hover {
    .title,
    &:global(.ant-collapse-item .ant-collapse-header .ant-collapse-arrow) {
      color: $teal;
    }
  }
  :global(.ant-motion-collapse-legacy-active) {
    @include transition-default(height opacity, 0.3s, cubic-bezier(0.645, 0.045, 0.355, 1), 0ms, !important);
  }
}

.wrapper {
  min-height: 600px;
  background-color: $pink-gray;
  justify-content: center;
  display: flex;
  flex-direction: column;
  position: relative;

  @media screen and (max-width: $breakpoint-md) {
    padding: 0 10px;
  }
  .panel {
    border: none;
  }
}

.cta,
.cta:hover {
  color: $teal;
}
