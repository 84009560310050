@import "styles/variables";

.text {
  position: relative;
  margin: 0;
  font-size: 34px;
  line-height: 50px;
  font-weight: 700;
  display: inline;
  h2 {
    font-size: inherit;
    line-height: inherit;
    margin: 0;
    font-weight: inherit;
    display: inline;
  }
  .marginRight {
    margin-right: 10px;
  }
  &.rotatedText {
    display: inline-block;
  }

  @media screen and (max-width: $breakpoint-lg) {
    font-size: 32px;
    line-height: 32px;
  }

  @media screen and (max-width: $breakpoint-md) {
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 10px;
  }
}

.word {
  position: absolute;
  display: flex;
}

.letter {
  min-width: 8px;
  display: inline-block;
  position: relative;
  float: left;
  transform: translateZ(25px);
  transform-origin: 50% 50% 25px;
  &.out {
    transform: rotateX(90deg);
    transition: transform 0.32s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  &.behind {
    transform: rotateX(-90deg);
    transition: none;
  }
  &.in {
    transform: rotateX(0deg);
    transition: transform 0.38s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
}

.wrapper {
  margin-bottom: 20px;
}

.legend {
  color: transparent;
  user-select: none;
}
