@import "styles/variables";
@import "styles/mixins";


.leftImage,
.rightImage,
.middleImage {
  cursor: pointer;
  position: relative;

  @include transition-default(opacity);
  &:hover {
    opacity: 0.8;
  }
}

.hidden {
  display: none;
}

.counter {
  font-size: 14px;
  opacity: 0.8;
}

.imageRow {
  height: 431px;
  display: grid;
  grid: 1fr / 15fr 9fr;
  column-gap: 10px;

  @media screen and (max-width: $breakpoint-lg) {
    padding-top: 10px;
    height: 214px;
    grid: 1fr / 10fr 14fr;
    column-gap: 0;
  }

  @media screen and (max-width: $breakpoint-sm) {
    grid: 1fr / 13fr 11fr;
    column-gap: 4px;
  }

  @media print {
    height: 180px;
  }

  div {
    max-height: 100%;
  }
}

.rightRow {
  flex-direction: column;
  height: 100%;
  align-items: stretch;
  display: flex;
  &.bigSize {
    flex-direction: column;

    @media screen and (max-width: $breakpoint-lg) {
      flex-direction: row;
    }

    @media screen and (max-width: $breakpoint-sm) {
      flex-direction: row;
    }
    .mobileCol {
      flex-grow: 2;

      @media screen and (max-width: $breakpoint-lg) {
        flex-grow: 1;
        margin-top: 0;
      }

      @media screen and (max-width: $breakpoint-sm) {
        flex-grow: 2;
      }

      .rightImage:not(:first-child) {
        margin-top: 5px;

        @media screen and (max-width: $breakpoint-sm) {
          margin-top: 2px;
        }
      }
    }

    @media screen and (max-width: $breakpoint-sm) {
      flex-direction: column;
    }
  }
  & > div {
    flex: 1;
    &:last-child {
      margin-bottom: 0;
    }

    @media screen and (max-width: $breakpoint-md) {
      margin: 2px 0 0;
    }
  }
  .middleImage {
    margin: 0 0 5px;

    @media screen and (max-width: $breakpoint-lg) {
      margin: 0 5px;
    }

    @media screen and (max-width: $breakpoint-sm) {
      margin: 0;
    }
  }
}

.enlargePlaceholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($black, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media print {
    display: none;
  }

  @include transition-default(background-color);
  &:hover {
    background-color: rgba($black, 0.3);
  }
  svg {
    color: $white;
  }
}
