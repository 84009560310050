.column {
  margin-bottom: 10px;
}

.city {
  margin-bottom: 10px;
  font-weight: 600;
}

.text {
  margin-bottom: 0;
}
