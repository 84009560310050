@import "styles/variables";
@import "styles/mixins";

.headingContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  opacity: 0;
  transform: translateY(100px);

  @media (max-width: $breakpoint-lg) {
    padding-top: 50px;
  }

  @include transition-default(opacity transform, 0.7s, ease, 0.2s);
}

.wrapper {
  min-height: 640px;
  padding-top: 86px;
  padding-bottom: 40px;
  display: flex;
  align-items: flex-end;
  background-color: rgba($black, 0.3);
  position: relative;
  &.active {
    .headingContainer {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media screen and (max-width: $breakpoint-lg) {
    min-height: 320px;
    padding-top: 22px;
    padding-bottom: 22px;
  }

  @media screen and (max-width: $breakpoint-sm) {
    min-height: 220px;
  }
}

.heading {
  margin-bottom: 24px;
  &.large {
    font-size: 80px;
    line-height: 80px;
    text-transform: uppercase;

    @media screen and (max-width: $breakpoint-lg) {
      font-size: 48px;
      line-height: 48px;
    }

    @media screen and (max-width: $breakpoint-md) {
      font-size: 32px;
      line-height: 32px;
    }

    @media screen and (max-width: $breakpoint-sm) {
      font-size: 26px;
      line-height: 28px;
    }
  }
  &.normal {
    font-size: 34px;
    line-height: 40px;

    @media screen and (max-width: $breakpoint-lg) {
      font-size: 18px;
      line-height: 24px;
    }

    @media screen and (max-width: $breakpoint-sm) {
      font-size: 16px;
    }
  }
  &.small {
    font-size: 24px;
    line-height: 32px;

    @media screen and (max-width: $breakpoint-lg) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  @media screen and (max-width: $breakpoint-lg) {
    line-height: 48px;
    margin-bottom: 18px;
  }

  @media screen and (max-width: $breakpoint-md) {
    line-height: 32px;
    margin-bottom: 12px;
  }

  @media screen and (max-width: $breakpoint-sm) {
    line-height: 28px;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

.filters {
  margin-top: 100px;
  position: relative;

  @media (max-width: $breakpoint-lg) {
    margin-top: 50px;
  }
}
