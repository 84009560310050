@import "styles/variables";
@import "styles/mixins";

.container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 80px;
}

.image {
  z-index: 1;
  border-radius: 50%;
  @include transition-default(transform, 300ms, ease-in-out);
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  background-color: $gray-800;
  border-radius: 50%;
  opacity: 0;
  transform: translateY(-100%);

  @include transition-default(opacity transform, 300ms, ease-in-out);
  @media screen and (max-width: $breakpoint-lg) {
    & > * {
      font-size: 16px;
      line-height: 20px;
    }
  }
}

.item {
  position: relative;
  width: 320px;
  height: 320px;
  margin: 15px;
  * {
    pointer-events: none;
  }
  &.hover {
    .image {
      transform: scale(0.5) translateY(100%);
    }
    .content {
      opacity: 1;
      transform: translate(0);
    }
  }

  @media screen and (max-width: $breakpoint-lg) {
    margin-bottom: 60px;
  }

  @media screen and (max-width: $breakpoint-sm) {
    width: 260px;
    height: 260px;
  }
}

.separator {
  width: 10%;
  height: 2px;
  margin: 0 auto 10px;
  background-color: $gray-600;
}

.quote {
  color: $gray-300;
}
