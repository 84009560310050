@import "styles/variables";

.wrapper {
  text-align: center;
  font-size: 64px;
  font-weight: 100;
  word-break: break-word;
  * {
    margin-bottom: 0;
  }

  @media screen and (max-width: $breakpoint-lg) {
    font-size: 48px;
  }

  @media screen and (max-width: $breakpoint-md) {
    font-size: 32px;
  }

  @media screen and (max-width: $breakpoint-sm) {
    font-size: 24px;
    line-height: 40px;
  }
}

.link {
  color: $black;
  text-decoration: none;
  span {
    font-weight: 100;
    transition: font-weight 0.5s, margin 0.5s;
    border-bottom: 1px solid;
    &:first-of-type {
      margin: 0 0 0 10px;
    }
    &:last-of-type {
      margin: 0 10px 0 0;
    }
  }
  &:hover {
    color: $black;
    text-shadow: 1px 1px 0 $gray-450, 2px 2px 0 $gray-450, 3px 3px 0 $gray-450;
    span {
      font-weight: 700;
      margin: 0;
    }
  }
}
