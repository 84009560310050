@import "styles/variables";

.wrapper {
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: $white;
}

.fullWidth {
  width: 100%;
}

.heading {
  margin-bottom: 8px;
}

.subheading {
  margin-bottom: 24px;
}
