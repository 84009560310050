@import "styles/variables";
@import "styles/mixins";

.container {
  display: flex;
  // TODO Fix negative left-right margins to gap: 30px property when supported by all browsers
  margin: 0 -15px;
}

.column {
  padding-left: 15px;
  padding-right: 15px;
}

.info {
  padding: 40px;
  background-color: $gray-light-bg;

  @include transition-default(background-color);

  @media screen and (max-width: $breakpoint-md) {
    padding: 20px;
  }
}

.infoText {
  @include transition-default(color);
}

.item {
  padding-bottom: 30px;
  &:hover {
    .info {
      background-color: $teal;
    }
    .infoText {
      color: $white;
    }
  }
}

.imageContainer {
  position: relative;
  width: 100%;
  height: auto;
  aspect-ratio: 1;
  @supports not (aspect-ratio: auto) {
    padding-top: 100%;
  }
}

.text {
  margin-top: 30px;
  margin-bottom: 30px;
}

.name,
.cta {
  color: $black;
}

.name {
  margin-right: 15px;
}

@media screen and (max-width: $breakpoint-sm) {
  .tagBlock {
    font-size: 14px;
    line-height: 20px;
  }
  .heading {
    font-size: 20px;
    line-height: 22px;
  }
}
