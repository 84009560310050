@import 'styles/variables';
@import 'styles/mixins';

.slider {
  width: 70%;
  margin: 0 auto;

  @media screen and (max-width: $breakpoint-sm) {
    width: 100%;
  }

  .arrow {
    z-index: 1;
    width: 56px;
    height: 56px;
    font-weight: 100;
    color: $white;
    top: 46%;

    @include transition-default(color);
    &:hover {
      color: $teal;
    }
    &:global(.slick-disabled) {
      color: $lightGray;
      cursor: not-allowed;
    }

    @media screen and (max-width: $breakpoint-md) {
      width: 42px;
      height: 42px;
    }

    @media screen and (max-width: $breakpoint-sm) {
      width: 28px;
      height: 28px;
    }
  }

  :global(.slick-list) {
    margin: 0 -8px;

    @media screen and (max-width: $breakpoint-md) {
      margin: 0;
    }
  }
}

.arrow-left {
  left: 15px;
}

.arrow-right {
  right: 15px;
}

.slideWrapper {
  margin: 0 8px;

  @media screen and (max-width: $breakpoint-md) {
    margin: 0;
  }

  .imageWrapper {
    position: relative;
    width: 100%;
    height: auto;
    aspect-ratio: 2/3;
    @supports not (aspect-ratio: auto) {
      padding-top: 150%;
    }

    @media screen and (max-width: $breakpoint-sm) {
      max-height: 450px;
      aspect-ratio: 5/6;
      @supports not (aspect-ratio: auto) {
        padding-top: 120%;
      }
    }
  }
}

.caption {
  margin: 8px 0;
}

.lastBlock {
  margin-bottom: 48px;
  @media screen and (max-width: $breakpoint-lg) {
    margin-bottom: 16px;
  }
}
