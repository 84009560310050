@import "styles/variables";
@import "styles/mixins";

.detail {
  position: fixed;
  top: 0;
  right: 80px;
  z-index: 1001;
  width: calc(100% - 100px);
  height: 100%;
  background-color: $white;
  opacity: 0;
  visibility: hidden;
  overflow-y: auto;

  @include transition-default(opacity right visibility, 0.15s);
  &.active {
    opacity: 1;
    right: 0;
    visibility: visible;

    @include transition-default(opacity right visibility, 0.45s, ease, 0.72s);
    .detailPhoto {
      display: block;
    }
  }

  @media screen and (max-width: $breakpoint-lg) {
    width: calc(100% - 50px);
  }
}

.detailInner {
  min-height: 100%;
  display: flex;
  flex-wrap: wrap;
}

.detailLeft {
  position: relative;
  width: 650px;
  min-height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba($white, 0.8);
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    background: linear-gradient(
      to right,
      rgba($white, 0) 9%,
      rgba($white, 0) 10%,
      rgba($white, 0) 38%,
      rgba($white, 1) 92%,
      rgba($white, 1) 100%
    );
  }

  @media screen and (max-width: $breakpoint-lg) {
    width: 100%;
    padding: 50px 50px 0;
    &::after {
      bottom: -1px;
      background: linear-gradient(
        to bottom,
        rgba($white, 0) 0%,
        rgba($white, 1) 83%,
        rgba($white, 1) 100%
      );
    }
  }

  @media screen and (max-width: $breakpoint-sm) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.detailPhoto {
  position: relative;
  text-align: center;
  z-index: 1;
  width: 100%;
  text-align: center;
  display: none;
  & > div {
    position: relative;
    background-size: cover;
    background-position: center;
    width: 75%;
    padding-bottom: 75%;
    box-shadow: 0 10px 22px rgba($black, 0.3);
    display: inline-block;
  }
}

.detailRight {
  position: relative;
  right: 0;
  width: calc(100% - 650px);
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 10%;

  @media screen and (max-width: $breakpoint-lg) {
    width: 100%;
    padding: 0 50px 20px;
    justify-content: flex-start;
  }

  @media screen and (max-width: $breakpoint-sm) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.detailHeader {
  margin-bottom: 30px;

  @media screen and (max-width: $breakpoint-lg) {
    text-align: center;
  }
}
