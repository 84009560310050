@import "styles/variables";
@import "styles/mixins";

.slider {
  width: 70%;
  margin: 0 auto;

  @media screen and (max-width: $breakpoint-sm) {
    width: 100%;
  }

  .arrow {
    z-index: 1;
    width: 56px;
    height: 56px;
    font-weight: 100;
    color: $white;
    top: 46%;

    @include transition-default(color);
    &:hover {
      color: $teal;
    }
    &:global(.slick-disabled) {
      color: $lightGray;
      cursor: not-allowed;
    }

    @media screen and (max-width: $breakpoint-md) {
      width: 42px;
      height: 42px;
    }

    @media screen and (max-width: $breakpoint-sm) {
      width: 28px;
      height: 28px;
    }
  }
}

.arrow-left {
  left: 15px;
}

.arrow-right {
  right: 15px;
}

.imageWrapper {
  position: relative;
  height: auto;
  aspect-ratio: 16/9;
  width: 100%;
  @supports not (aspect-ratio: auto) {
    padding-top: 56.25%;
  }

  @media screen and (max-width: $breakpoint-sm) {
    max-height: 300px;
    aspect-ratio: 6/5;
    @supports not (aspect-ratio: auto) {
      padding-top: 83.3%;
    }
  }
}

.caption {
  margin: 8px 0;
}

.lastBlock {
  margin-bottom: 48px;
  @media screen and (max-width: $breakpoint-lg) {
    margin-bottom: 16px;
  }
}
