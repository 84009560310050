@import "styles/variables";
@import "styles/mixins";

.wrapper {
  position: relative;
  overflow: hidden;
  max-width: 100%;
}

.ctaContainer {
  margin-bottom: 12px;
  opacity: 0;
  &:first-of-type {
    transform: translateX(-200px);
  }
  &:not(:first-of-type) {
    margin-left: 15px;
    transform: translateX(200px);

    @media screen and (max-width: $breakpoint-sm) {
      margin-left: 0;
    }
  }

  @include transition-default(opacity transform, 1s, ease, 1s);
}

.container {
  padding-top: 120px;
  padding-bottom: 120px;
  &.active {
    .ctaContainer {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @media screen and (max-width: $breakpoint-lg) {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  @media screen and (max-width: $breakpoint-sm) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.text {
  margin-top: 24px;
  margin-bottom: 32px;
}

.ctas {
  display: flex;

  @media screen and (max-width: $breakpoint-sm) {
    flex-direction: column;
  }
}

.cta {
  padding: 10px 38px;
  background-color: $teal;
  color: $white;
  display: inline-block;

  @include transition-default(opacity);
  &:hover {
    color: $white;
    opacity: 0.8;
  }
}
