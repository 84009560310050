@import 'styles/variables';
@import 'styles/mixins';

@mixin slideY($translateY) {
  @keyframes slide-#{$translateY} {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY($translateY);
    }
  }
}
@include slideY(50px);
@include slideY(40px);
@include slideY(30px);

@mixin hoverSlide {
  &::before {
    background-color: rgba($teal, 0.85);
    z-index: 1;
  }

  .hoverHeading {
    display: block;
    animation: slide-50px 0.5s ease forwards;
    
    @media screen and (max-width: $breakpoint-md) {
      animation: slide-40px 0.5s ease forwards;
    }
    
    @media screen and (max-width: $breakpoint-sm) {
      animation: slide-30px 0.5s ease forwards;
    }
  }

  .heading {
    display: none;
  }
  .textContainer {
    z-index: 1;
    opacity: 1;
    transform: translateY(0);
  }
}

.slider {
  margin-bottom: 50px;
  user-select: text;
  .arrow {
    z-index: 1;
    width: 56px;
    height: 56px;
    font-weight: 100;
    color: $white;

    @include transition-default(color);
    &:hover {
      color: $teal;
    }
    &:global(.slick-disabled) {
      color: $lightGray;
      cursor: not-allowed;
    }

    @media screen and (max-width: $breakpoint-md) {
      width: 42px;
      height: 42px;
    }

    @media screen and (max-width: $breakpoint-sm) {
      width: 28px;
      height: 28px;
    }
  }
  :global(.slick-slide.slick-active) {
    padding-right: 15px;

    @media screen and (max-width: $breakpoint-md) {
      padding-right: 0;
    }
  }
  :global(.slick-slide.slick-active + .slick-slide.slick-active) {
    padding-left: 15px;
    padding-right: 0;

    @media screen and (max-width: $breakpoint-md) {
      padding-left: 0;
    }
  }
}

.arrow-left {
  left: 15px;
}

.arrow-right {
  right: 15px;
}

.handle {
  height: 3px;
  background-color: $gray-600;
  cursor: pointer;
}

.slide {
  height: 700px;
  padding: 60px 40px;
  position: relative;

  @media screen and (max-width: $breakpoint-md) {
    height: 600px;
    padding: 50px 30px;
  }

  @media screen and (max-width: $breakpoint-sm) {
    height: 500px;
    padding: 40px 20px;
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    @include transition-default(background-color, 0.5s);
  }
  &.touched {
    @include hoverSlide;
  }
  // Media query only for desktop
  @media screen and (hover: hover) and (pointer: fine) {
    &:hover {
      @include hoverSlide;
    }
  }
}

.info {
  z-index: 2;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.heading {
  position: absolute;
  bottom: 40px;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre-line;

  @media screen and (max-width: $breakpoint-sm) {
    font-size: 20px;
    line-height: 22px;
  }
}

.textContainer {
  margin-top: 50px;
  opacity: 0;
  position: relative;
  z-index: -1;
  transform: translateY(-30px);

  & > div > p {
    & > a,
    & > a:link,
    & > a:visited {
      color: $white;
      text-decoration: underline;
    }  
  }

  @media screen and (max-width: $breakpoint-md) {
    margin-top: 40px;
    transform: translateY(-20px);
  }

  @media screen and (max-width: $breakpoint-sm) {
    margin-top: 30px;
    transform: translateY(-15px);
  }

  @include transition-default(opacity transform, 0.5s);
}

.subheading {
  margin-bottom: 12px;
}

.hoverHeading {
  display: none;
  position: relative;
  bottom: 40px;
}
