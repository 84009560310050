@import "styles/variables";

div.map {
  height: 400px;
}

:global(.mapBlockLink) {
  color: $black;
  &:hover {
    color: $teal;
  }
}
