@import "styles/variables";

.videoContainer {
  width: 100%;
  position: relative;
  cursor: pointer;
  &.vertical {
    aspect-ratio: 406/720;
    @supports not (aspect-ratio: auto) {
      padding-top: 177.3399014778325%;
      .videoPlayer {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
    @media screen and (min-width: calc($breakpoint-md + 1px)) {
      margin-left: auto;
      max-width: 300px;
      grid-column: 2;
      grid-row: 1 / -1;
    }
  }
  &.horizontal {
    aspect-ratio: 16/9;
    @supports not (aspect-ratio: auto) {
        padding-top: 56.25%;
        .videoPlayer {
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    @media screen and (min-width: calc($breakpoint-md + 1px)) {
      grid-column: 2;
      grid-row: 1 / -1;
      &.withHeading {
        grid-row: 2 / -1;
      }
    }
  }
  :global(div.video-js) {
    background-color: $white;
  }
}

.videoPlayer {
  font-size: 16px;
}

video::-webkit-media-controls{
  display: none;
  -webkit-appearance: none;
}

.playButtonContainer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  .playBtn {
    position: absolute;
    right: 32px;
    bottom: 32px;
    color: $teal;
  }
}
