@import "styles/variables";
@import "styles/mixins";

.column {
  min-height: 500px;

  @media screen and (max-width: $breakpoint-md) {
    min-height: 400px;
  }

  @media screen and (max-width: $breakpoint-sm) {
    min-height: 300px;
  }
}

.imageGridPortrait {
  display: grid;
  grid: auto-flow / 1fr 1fr 1fr;
  gap: 20px;

  @media screen and (max-width: $breakpoint-lg) {
    grid: auto-flow / 1fr 1fr;
  }
  @media screen and (max-width: $breakpoint-md) {
    grid: auto-flow / 1fr;
    gap: 10px;
  }
}

.scoreContainer {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 80px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid $white;
  opacity: 0.7;
  background-color: $black;

  @include transition-default(opacity);

  @media screen and (max-width: $breakpoint-lg) {
    width: auto;
    height: auto;
    padding: 10px 5px;
  }
}

.image {
  position: relative;
  padding: 40px;
  &:hover {
    .scoreContainer {
      opacity: 0;
    }
  }

  @media screen and (max-width: $breakpoint-md) {
    padding: 30px;
  }

  @media screen and (max-width: $breakpoint-sm) {
    padding: 20px;
  }
}

.noMargin {
  margin-bottom: 0;
}

.scoreTitle {
  @media screen and (max-width: $breakpoint-sm) {
    font-size: 20px;
    line-height: 22px;
  }
}
