@mixin transition-default($properties: all, $duration: 300ms, $method: ease, $delay: 0ms, $content: null) {
  $prefixes: -webkit- -moz- -ms- -o-;

  @each $prefix in $prefixes {
    $value: ();

    @each $property in $properties {
        @if length($value) > 0 {
          $value: append($value, unquote(",") #{$property} $duration $method $delay);
        }
        @else {
          $value: append($value, #{$property} $duration $method $delay);
        }
    }
    #{$prefix}transition: $value $content;
  }
}

@mixin background-prop($position: center, $size: contain, $repeat: no-repeat) {
  background-position: $position;
  background-size: $size;
  background-repeat: $repeat;
}
