@import 'styles/variables';
@import 'styles/mixins';

.container {
  display: flex;
}

.column {
  padding-left: 15px;
  padding-right: 15px;
  &:first-of-type {
    padding-left: 0;
  }
  &:last-of-type {
    padding-right: 0;
  }
}

.info {
  padding: 40px;
  background-color: $gray-light-bg;

  @include transition-default(background-color);

  @media screen and (max-width: $breakpoint-md) {
    padding: 20px;
  }
}

.infoText {
  @include transition-default(color);
}

.item {
  padding-bottom: 30px;
  &:hover {
    .info {
      background-color: $teal;
    }
    .infoText,
    .toggleReview {
      color: $white;
    }
  }
}

.imageContainer {
  position: relative;
  width: 100%;
  height: auto;
  &.landscape {
    aspect-ratio: 1.5;
    @supports not (aspect-ratio: auto) {
      padding-top: 150%;
    }

    @media screen and (max-width: $breakpoint-sm) {
      max-height: 300px;
      aspect-ratio: 1.2;
      @supports not (aspect-ratio: auto) {
        padding-top: 120%;
      }
    }
  }
  &.portrait {
    aspect-ratio: 2/3;
    @supports not (aspect-ratio: auto) {
      padding-top: 67%;
    }

    @media screen and (max-width: $breakpoint-sm) {
      max-height: 450px;
      aspect-ratio: 5/6;
      @supports not (aspect-ratio: auto) {
        padding-top: 83%;
      }
    }
  }
}

.text {
  margin-bottom: 30px;
}

.cta {
  color: $black;
  text-transform: uppercase;
}

.heading {
  text-transform: uppercase;
  margin-bottom: 30px;
}

.tagBlock {
  margin-bottom: 0;
  & + .tagBlock {
    margin-bottom: 16px;
  }
}

.toggleReview {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  font-size: 16px;
  font-weight: 600;
  margin-left: 4px;
  text-transform: lowercase;
  color: $black;
  @include transition-default(opacity color);
  &:hover {
    opacity: 0.8;
  }
  cursor: pointer;
}

.showMore {
  margin: 40px auto;
  text-align: center;
  .button {
    padding: 28px 76px;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
  }
}

.emptyText {
  padding-bottom: 40px;

  @media screen and (max-width: $breakpoint-lg) {
    padding-bottom: 20px;
  }
}
