@import "styles/variables";
@import "styles/mixins";

.hamburger {
  display: none;
  width: 48px;
  height: 48px;
  border: none;
  background-color: transparent;
  outline: none;
  position: relative;
  overflow: visible;
  cursor: pointer;

  @media screen and (max-width: $breakpoint-lg) {
    display: block;
    order: 1;
  }
  span {
    position: absolute;
    height: 2px;
    width: 14px;
    background-color: $black;
    display: block;
    border-radius: 2px;
    transform: rotate(0deg);
    opacity: 1;
    right: 0;

    @include transition-default(opacity top left transform, 500ms);
    &:nth-of-type(1) {
      top: 18px;
    }
    &:nth-of-type(2) {
      top: 23px;
    }
    &:nth-of-type(3) {
      top: 28px;
    }
  }
  &.active {
    span {
      &:nth-of-type(1) {
        top: 23px;
        transform: rotate(135deg);
      }
      &:nth-of-type(2) {
        opacity: 0;
      }
      &:nth-of-type(3) {
        top: 23px;
        transform: rotate(-135deg);
      }
    }
  }
}
