@import "styles/variables";

.wrapper {
  min-height: 427px;
  padding-top: 80px;
  padding-bottom: 80px;
  display: flex;
  align-items: center;
  position: relative;

  @media screen and (max-width: $breakpoint-lg) {
    min-height: 360px;
  }

  @media screen and (max-width: $breakpoint-sm) {
    min-height: 256px;
    padding-top: 50px;
    padding-bottom: 12px;
  }
}

.filters {
  margin-top: 100px;
  position: relative;

  @media screen and (max-width: $breakpoint-lg) {
    margin-top: 60px;
  }

  @media screen and (max-width: $breakpoint-sm) {
    margin-top: 0;
  }
}

.image {
  z-index: -1;
}
