@import "styles/variables";

.name {
  margin-bottom: 0;
}

.title {
  margin-top: 5px;
  font-weight: normal;
}
