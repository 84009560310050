@import "styles/variables";
@import "styles/mixins";

.container {
  &.ready {
    .person:not(.selected) {
      opacity: 0 !important;
      pointer-events: none;
    }
  }
}

.row:hover {
  .person:not(:hover) {
    opacity: 0.6;

    @media (pointer: coarse) {
      opacity: 1;
    }
  }
}

.outer {
  position: relative;
}

.person {
  cursor: pointer;
  opacity: 1;

  @include transition-default(all, 0.45s);
  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background: $teal;
    height: 0;

    @include transition-default(all, 0.45s);
  }
  &:not(.personExitDone) {
    .personImage {
      opacity: 1;
    }
  }
  &.selected,
  &.personExit {
    .personImage {
      opacity: 0;
    }
  }
}

.personImage {
  width: 100%;
  padding-bottom: 100%;
  background-position: center;
  background-size: cover;
  position: relative;
  opacity: 0;

  @include transition-default(opacity);
  img {
    opacity: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
}

.personDetails {
  margin-top: 10px;
  padding: 0 20px;

  @include transition-default(all);

  @media screen and (max-width: $breakpoint-lg) {
    padding: 0 10px;
  }
}

.iconContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;

  @include transition-default(opacity visibility);
}

.title {
  margin-top: 5px;
}

.icon {
  font-size: 24px;
  color: $white;
}

.personEnter {
  transition-duration: 0s;
  &:before {
    height: 0;
  }
  .personDetails {
    margin-top: 10px;
    opacity: 1;
  }
}

.personEnterActive {
  transition-duration: 0.45s;
  &:before {
    height: 100%;
  }
  .personDetails {
    margin-top: -20px;
    opacity: 0;
  }
}

.personEnterDone {
  position: fixed;
  left: 0 !important;
  top: 0 !important;
  z-index: 1001;
  height: 100% !important;
  width: 100px !important;
  opacity: 1 !important;

  @media screen and (max-width: $breakpoint-lg) {
    width: 50px !important;
  }
  &:before {
    height: 100%;
  }
  .personDetails {
    margin-top: 10px;
    opacity: 0;
  }
  .iconContainer {
    opacity: 1;
    visibility: visible;
  }
}

.personExit {
  position: fixed;
  transition-duration: 0.45s;
  &:before {
    height: 100%;
  }
  .personDetails {
    margin-top: -20px;
    opacity: 0;
  }
}

.personExitActive {
  &:before {
    height: 0;
    transition-delay: 0.28s;
  }
  .personDetails {
    margin-top: 10px;
    transition-delay: 0.63s;
    opacity: 1;
  }
}

.personExitDone {
  top: 0 !important;
  left: 0 !important;

  .personImage {
    opacity: 1;
  }
}
