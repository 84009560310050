@import "styles/variables";
@import "styles/mixins";


.text {
  line-height: 32px;
  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: $breakpoint-lg) {
    line-height: 20px;
  }
}

.desktopText {
  white-space: pre-wrap;
  word-break: break-word;

  @media screen and (max-width: $breakpoint-lg) {
    display: none;
  }

  @media print {
    & > * {
      font-size: 10px;
      line-height: 15px;
    }
  }
}

.desktopHeading {
  @media print {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
  }
}

.subheading {
  font-weight: bold;
}

.mobileText {
  display: none;
  white-space: pre-wrap;
  word-break: break-word;

  @media screen and (max-width: $breakpoint-lg) {
    display: block;
  }
}

.descriptionRow {
  display: grid;
  grid: auto-flow / 1fr 1fr;
  column-gap: 40px;

  @media screen and (max-width: $breakpoint-lg) {
    grid: auto-flow / 1fr;
  }
}
