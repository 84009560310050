@import "styles/variables";
@import "styles/mixins";

.imageContainer {
  position: relative;

  @media screen and (max-width: $breakpoint-lg) {
    min-height: 250px;
  }
}

.card {
  padding: 50px 20px 50px 40px;
  background-color: $gray-light-bg;

  @include transition-default(background-color);
  * {
    @include transition-default(color);
  }

  @media screen and (max-width: $breakpoint-md) {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 20px;
  }
}


.container:hover {
  .card {
    background-color: $teal;
  }
  * {
    color: $white;
  }
}

.cta {
  color: $black;
}

.heading {
  margin-bottom: 16px;

  @media screen and (max-width: $breakpoint-lg) {
    font-size: 24px;
    line-height: 28px;
  }
}

.cardText {
  min-height: 290px;

  @media screen and (max-width: $breakpoint-xxl) {
    min-height: 355px;
  }

  @media screen and (max-width: $breakpoint-xl) {
    min-height: 480px;
  }

  @media screen and (max-width: $breakpoint-lg) {
    min-height: 400px;
  }

  @media screen and (max-width: $breakpoint-md) {
    min-height: 200px;
  }

  @media screen and (max-width: $breakpoint-sm) {
    min-height: 280px;
  }
}
