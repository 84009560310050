@import "styles/mixins";
@import "styles/variables";

.text {
  margin-bottom: 0;
}

.collapse {
  height: 0;
  min-height: 0;
  overflow: hidden;

  @include transition-default(min-height, 0.35s, linear);
}

.button {
  background-color: transparent;
  border: none;
  padding: 0;
  font-size: inherit;
  font-weight: normal;
  text-transform: none;
}

.heading {
  @media screen and (max-width: $breakpoint-sm) {
    font-size: 24px;
    line-height: 28px;
  }
}
