@import "styles/variables";
@import "styles/mixins";

.inner {
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  opacity: 0;

  @include transition-default(background-color opacity, 0.5s);
}

.container {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  &:hover {
    .inner {
      background-color: rgba($teal, 0.85);
      opacity: 1;
    }
  }
}

.name {
  margin-bottom: 0;
}

.tags {
  padding: 15px 0;
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  margin: 0 -10px;

  @media screen and (max-width: $breakpoint-lg) {
    li {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}

.tag {
  @media screen and (max-width: $breakpoint-lg) {
    margin-left: 10px;
    margin-right: 10px;
  }
  & + .tag {
    margin-left: 50px;

    @media screen and (max-width: $breakpoint-lg) {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

.tagBtn {
  font-weight: 600;
  background-color: transparent;
  color: $black;
  border: none;
  outline: none;
  cursor: pointer;

  @media screen and (max-width: $breakpoint-sm) {
    line-height: 20px;
    font-size: 16px;
  }
  @include transition-default(color);
  &.selected,
  &:hover {
    color: $teal;
  }
}

.enter {
  opacity: 0.01;
  transform: scale(0) translateY(30px);
}

.enterActive {
  opacity: 1;
  transform: scale(1) translateY(0);

  @include transition-default(all, 0.3s, ease-in);
}

.exit {
  opacity: 1;
  transform: scale(1) translateY(0);
}

.exitActive {
  opacity: 0.01;
  transform: scale(0) translateY(30px);

  @include transition-default(all, 0.3s, ease-in);
}
