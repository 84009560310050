@import "styles/variables";
@import "styles/mixins";

.column {
  display: flex;
}

.imageContainer {
  position: relative;
  width: 100%;
  min-height: 300px;
  margin: 5px;
  padding: 30px;
  overflow: hidden;

  &:before {
    content: '';
    display: block;
    position: absolute;
    background-color: rgba($black, 0.5);
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    opacity: 0;

    @include transition-default(opacity, 0.5s);
  }

  @media screen and (max-width: $breakpoint-lg) {
    padding: 20px;
  }

  &:hover {
    &:before {
      opacity: 1;
    }

    .image {
      & > div {
        transform: scale(1.3);
      }
    }

    .back {
      border-color: $white;
    }

    .text {
      opacity: 1;
    }
  }
}

.image {
  z-index: -1;
  width: 100%;
  height: 100%;
  & > div {
    @include transition-default(transform, 0.5s);
  }
}

.back {
  height: calc(100% - 60px);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid transparent;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 60px);
  margin: 30px;
  overflow: hidden;
  z-index: 1;

  @include transition-default(border-color, 0.5s);
}

.text {
  opacity: 0;

  @include transition-default(opacity, 0.5s);
}
