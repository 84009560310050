@import "styles/variables";


.hero {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 350px;
  padding: 20px 0;
  position: relative;
  .headingText {
    max-width: 85%;
    text-shadow: 0 3px 8px rgba($dark, 0.3);
  }

  @media screen and (max-width: $breakpoint-lg) {
    min-height: 300px;
  }

  @media screen and (max-width: $breakpoint-md) {
    min-height: 200px;
  }

  @media screen and (max-width: $breakpoint-sm) {
    min-height: 124px;
    padding: 15px 0;
  }
}

.text {
  max-width: 676px;
  margin: 24px auto 0;
  & > * {
    margin: 0;
  }
}
