@import "styles/variables";


.AmenityContainer {
  background-color: $gray-bg;
  padding: 33px 0 27px;
  color-adjust: exact !important;

  @media screen and (max-width: $breakpoint-lg) {
    padding: 16px 15px 31px;
  }

  @media print {
    padding: 5px 10px;
  }
}

.AmenityCard {
  text-align: center;
  p {
    color: $black;
    font-size: 16px;
    line-height: 22px;
    margin: 7px auto 0;
    max-width: 110px;

    @media screen and (max-width: $breakpoint-lg) {
      font-size: 12px;
      line-height: 18px;
      margin-top: 8px;
      max-width: 70px;
    }

    @media print {
      margin: 0 auto;
    }
  }
  svg {
    color: $teal;
    font-size: 24px;

    @media screen and (max-width: $breakpoint-lg) {
      font-size: 20px;
    }

    @media screen and (max-width: $breakpoint-lg) {
      font-size: 16px;
    }
  }
}

.dot {
  width: 6px;
  height: 6px;
  background-color: rgba($black, 0.1);
  border-radius: 50%;
}

.dotList {
  margin: 0;
  li {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    &:global(.slick-active) {
      div {
        background-color: rgba($black, 0.4);
      }
    }
  }
}

.screenView {
  @media print {
    display: none;
  }
}

.printView {
  display: none;

  @media print {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    & > div {
      flex: 0 0 16%;
      &:nth-child(n + 7) {
        margin-top: 10px;
      }
      svg {
        font-size: 14px;
      }
      p {
        font-size: 10px;
      }
    }
  }
}
