@import "styles/variables";
@import "styles/mixins";

.visibleSection {
  z-index: 1;
  @media screen and (min-width: $min-breakpoint-lg) {
    display: block;
    position: fixed;
    padding: 20px 0 30px;
    top: 72px;
    left: 0;
    width: 100%;
  }

  @media screen and (max-width: $breakpoint-lg) {
    top: 60px;
    padding: 0;
  }
}

.navWrapper {
  display: flex;
  align-items: center;

  @media screen and (max-width: $breakpoint-lg) {
    width: 100%;
    display: block;
  }
  & > .subSection {
    display: none;

    @media screen and (max-width: $breakpoint-lg) {
      display: block;
    }
    &.visible {
      @extend .visibleSection;
    }
  }
  &:hover {
    & > .subSection {
      @extend .visibleSection;
    }
  }
}

.subSection {
  background-color: $white;
  position: relative;
  & > .navContainer {
    @media screen and (max-width: $breakpoint-lg) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .linkWrapper {
    display: block;
    margin: 0;

    @media  screen and (max-width: $breakpoint-lg) {
      display: flex;
      margin: 0 15px;
    }
    .link,
    .link:link,
    .link:visited {
      color: $black;
    }
  }

  @media screen and (max-width: $breakpoint-lg) {
    position: fixed;
    top: 60px;
    left: 100%;
    height: 100%;
    width: 100%;
    z-index: 10;
    .linkWrapper {
      .link,
      .link:link,
      .link:visited {
        font-weight: 400;
      }
    }

    @include transition-default(left);
    &.visible {
      left: 0;
    }
    &.hidden {
      @media screen and (max-width: $breakpoint-lg) {
        display: none;
      }
    }
  }
}

.subIcon {
  display: none;

  @media screen and (max-width: $breakpoint-lg) {
    display: inline-block;
    font-size: 20px;
    margin: -15px -10px -15px 0;
    padding: 13px 0 13px 20px;
    & > div {
      transform: rotate(-90deg);
    }
    svg {
      color: $teal;
      font-size: 20px;
    }
  }
}

.linkWrapper {
  margin: 0 12px;

  @media screen and (max-width: $breakpoint-lg) {
    box-shadow: inset 0 -1px 0 0 $gray-border;
    padding: 15px 0;
    margin: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .link,
  .link:link,
  .link:visited {
    font-weight: 600;
    text-transform: uppercase;
    color: $black;
    display: inline-block;

    @include transition-default(transform color, 200ms);
    &.bold {
      font-weight: 700;
      pointer-events: none;
    }
    &.teal {
      color: $teal;
    }
    &:not(span):hover {
      transform: scale(1.1);
    }

    @media screen and (max-width: $breakpoint-lg) {
      color: $black;
    }
  }
  &.backButton {
    display: none;
    svg {
      color: $teal;
    }

    @media screen and (max-width: $breakpoint-lg) {
      display: block;
      & > button {
        margin: -14px -8px -14px -13px;
        height: 48px;
        width: 48px;
      }
      & > span {
        color: $black;
        text-transform: uppercase;
        font-weight: 600;
      }
    }
  }
}

.masonryGrid {
  display: flex;
  @media screen and (max-width: $breakpoint-lg) {
    display: block;
  }
}

.masonryColumn {
  padding-right: 15px;
  &:last-child {
    padding-right: 0;
  }

  @media screen and (max-width: $breakpoint-lg) {
    padding-right: 0;
    max-height: calc(100vh - 115px);
    overflow: auto;
  }

  & > .linkWrapper {
    margin-top: 10px;

    @media screen and (max-width: $breakpoint-lg) {
      margin-top: 0;
    }
  }
}
