@import "styles/variables";


.heroContainer {
  @media screen and (max-width: $breakpoint-lg) {
    padding-left: 0;
    padding-right: 0;
  }

  @media print {
    padding-left: 30px !important;
    padding-right: 30px !important;
    break-inside: avoid;
  }
  & > * {
    color: $white;
  }
}

.schoolHero {
  min-height: 640px;
  padding: 86px 0 40px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  background-color: $gray-border;
  color-adjust: exact !important;
  position: relative;

  @media screen and (max-width: $breakpoint-lg) {
    padding: 22px 0;
    min-height: 320px;
  }

  @media screen and (max-width: $breakpoint-sm) {
    padding: 22px 0;
    min-height: 220px;
  }

  @media print {
    min-height: 180px;
  }
}

.heroTitle {
  @media screen and (max-width: $breakpoint-lg) {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 5px;
  }

  @media print {
    font-size: 25px;
    line-height: 30px;
  }
}

.schoolGroup {
  font-weight: 700;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: 0.02em;
  margin-top: 24px;

  @media screen and (max-width: $breakpoint-lg) {
    font-size: 16px;
    line-height: 26px;
    margin: 0;
  }

  @media screen and (max-width: $breakpoint-sm) {
    font-size: 14px;
    line-height: 16px;
  }

  @media print {
    font-size: 20px;
    line-height: 25px;
  }
}

.schoolName {
  @media screen and (max-width: $breakpoint-lg) {
    font-size: 16px;
    line-height: 24px;
    margin: 0;
  }

  @media screen and (max-width: $breakpoint-lg) {
    font-size: 26px;
    line-height: 28px;
  }
}
