@import "styles/variables";


.heading {
  color: $pink;
}

.subheading {
  color: $lightGray;
  b {
    display: block;
    color: $pink;
    font-size: 64px;
    line-height: 64px;
    font-weight: bold;
    margin-bottom: 24px;

    @media screen and (max-width: $breakpoint-lg) {
      font-size: 48px;
      line-height: 48px;
      margin-bottom: 18px;
    }

    @media screen and (max-width: $breakpoint-md) {
      font-size: 32px;
      line-height: 32px;
      margin-bottom: 12px;
    }

    @media screen and (max-width: $breakpoint-sm) {
      font-size: 24px;
      line-height: 28px;
    }
  }
}

.firstWord {
  color: $black;
}
