@import "styles/variables";
@import "styles/mixins";

.slider {
  .tag,
  .heading {
    letter-spacing: 2px;
  }
  &.ready {
    :global(.slick-slide.slick-active) {
      .borderTop {
        width: 300px;

        @media screen and (max-width: $breakpoint-md) {
          width: 250px;
        }

        @media screen and (max-width: $breakpoint-sm) {
          width: 150px;
        }
      }
      .borderRight {
        height: 100%;
      }
      .borderBottom {
        width: 300px;
        left: 0;

        @media screen and (max-width: $breakpoint-md) {
          width: 250px;
        }

        @media screen and (max-width: $breakpoint-sm) {
          width: 150px;
        }
      }
      .borderLeft {
        height: 100%;
      }
      .tag,
      .heading {
        transform: translateX(0);
      }
      .cta,
      .text {
        transform: translateX(0);
      }
    }
    .slideImage {
      transform: translateX(0);
    }
  }
  :global {
    .slick-dots {
      position: relative;
      bottom: 105px;
      display: flex;
      justify-content: flex-end;
      opacity: 0;

      @include transition-default(opacity);
      @media screen and (hover: none) and (pointer: coarse) {
        opacity: 1;
      }
      li {
        width: auto;
        height: auto;
        &.slick-active > div {
          background-color: $teal;
        }
      }
    }
  }
  &:hover :global(.slick-dots) {
    opacity: 1;
  }
}

.dotsList {
  margin: 0;
  display: flex;
}

.dot {
  width: 30px;
  height: 5px;
  background-color: #ffffff;
}

.slide {
  height: 700px;
  position: relative;
  background-color: $white;
  overflow: hidden;

  @media screen and (max-width: $breakpoint-md) {
    height: 600px;
  }

  @media screen and (max-width: $breakpoint-sm) {
    height: 500px;
  }
}

.slideImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateX(100%);

  @include transition-default(transform, 0.5s);
}

.slideInfo {
  position: absolute;
  top: 100px;
  bottom: 100px;
  left: 20%;
  max-width: 100%;
  min-height: 500px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: $breakpoint-md) {
    left: 10%;
    width: 400px;
    min-height: 400px;
  }

  @media screen and (max-width: $breakpoint-sm) {
    left: 5%;
    width: 300px;
    min-height: 320px;
    padding: 20px;
  }
}

.borderTop,
.borderRight,
.borderBottom,
.borderLeft {
  position: absolute;
  background-color: $teal;

  @include transition-default(all, 0.5s);
}

.borderTop,
.borderBottom {
  width: 0;
  height: 5px;
  left: 300px;

  @media screen and (max-width: $breakpoint-md) {
    height: 4px;
    left: 250px;
  }

  @media screen and (max-width: $breakpoint-sm) {
    height: 3px;
    left: 150px;
  }
}

.borderRight,
.borderLeft {
  width: 5px;
  height: 0;

  @media screen and (max-width: $breakpoint-md) {
    width: 4px;
  }

  @media screen and (max-width: $breakpoint-sm) {
    width: 3px;
  }
}

.borderTop {
  top: 0;
  left: 0;
  transition-delay: 0.25s;
}

.borderRight {
  top: 0;
  left: 300px;
  transition-delay: 0.75s;

  @media screen and (max-width: $breakpoint-md) {
    left: 250px;
  }

  @media screen and (max-width: $breakpoint-sm) {
    left: 150px;
  }
}

.borderBottom {
  bottom: 0;
  transition-delay: 1.25s;
}

.borderLeft {
  bottom: 0;
  left: 0;
  transition-delay: 1.75s;
}

.infoText {
  position: relative;
  z-index: 1;
}

.inlineContainer {
  display: inline-block;
  overflow: hidden;
  max-width: 100%;
  width: 100%;
}

.tag {
  display: block;
  transform: translateX(100%);

  @include transition-default(transform, 1s, ease-out, 1s);

  @media screen and (max-width: $breakpoint-lg) {
    font-size: 14px;
    line-height: 20px;
  }
}

.headingContainer {
  padding-top: 20px;
  padding-bottom: 20px;
  display: block;
}

.heading {
  margin: 0;
  font-size: 64px;
  line-height: 1.3;
  transform: translateX(-100%);

  @include transition-default(transform, 1s, ease-out, 1s);

  @media screen and (max-width: $breakpoint-lg) {
    font-size: 48px;
    line-height: 48px;
  }

  @media screen and (max-width: $breakpoint-md) {
    font-size: 32px;
    line-height: 32px;
  }

  @media screen and (max-width: $breakpoint-sm) {
    font-size: 24px;
    line-height: 28px;
  }
}

.text {
  transform: translateX(-100%);

  @include transition-default(transform, 1s, ease-out, 1.5s);

  @media screen and (max-width: $breakpoint-sm) {
    font-size: 14px;
    line-height: 20px;
  }
}

.cta {
  display: inline-block;
  color: white;
  transform: translateX(-100%);
  &:hover,
  &:focus {
    color: $teal;
  }

  @include transition-default(transform, 1s, linear, 1.5s);

  @media screen and (max-width: $breakpoint-sm) {
    font-size: 14px;
    line-height: 20px;
  }
}

.icon {
  margin-left: 10px;
}
