@import "styles/variables";


.content {
  *:last-child {
    margin-bottom: 0;
  }
  h2 {
    font-size: 34px;

    @media screen and (max-width: $breakpoint-lg) {
      font-size: 24px;
      line-height: 28px;
    }
  }

  @media screen and (max-width: $breakpoint-lg) {
    h3 {
      font-size: 20px;
      line-height: 22px;
    }
    h4 {
      font-size: 18px;
      line-height: 20px;
    }
    h5,
    h6 {
      font-size: 16px;
      line-height: 18px;
    }
    p {
      font-size: 16px;
      line-height: 20px;
    }
  }
  a,
  a:link,
  a:visited {
    color: $teal;
  }
  ul,
  ol,
  dl {
    list-style-position: inside;
    & ul,
    & ol {
      padding-left: 20px;
    }
  }
  ol {
    list-style-type: decimal;
    ol {
      list-style-type: lower-alpha;
      ol {
        list-style-type: lower-roman;
        ol {
          list-style-type: decimal;
          ol {
            list-style-type: lower-alpha;
          }
        }
      }
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
  }
}
